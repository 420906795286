<template>

	<!-- Delivery Table Card -->

	<a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
		<a-table :columns="columns" :data-source="data" :pagination="false">


			<template slot="func" slot-scope="func">
				<div class="transaction-info">
					<h6 class="m-0">{{ func.job }}</h6>
					<p class="m-0 font-regular text-muted">{{ func.department }}</p>
				</div>
			</template>

			<template slot="owner" slot-scope="owner">
				<div class="source-info">
					<h6 class="m-0">{{ owner.name }}</h6>
					<p class="m-0 font-regular text-muted">Apartment {{ owner.apartment }}</p>
				</div>
			</template>

			<template slot="status" slot-scope="row">
				<a-dropdown :trigger="['click']">
					<a-tag class="tag-status"
						:class="[row.status=='Picked Up' ? 'ant-tag-muted' : '',
									row.status=='Awaiting Pick Up' ? 'ant-tag-success' : '']"
						@click="e => e.preventDefault()">
					{{ row.status }} <a-icon type="down" />
					</a-tag>
					<a-menu slot="overlay">
					<a-menu-item>
						<a href="javascript:;" v-on:click="updateStatus(row, newStats='Awaiting Pick Up')">Awaiting Pick Up</a>
					</a-menu-item>
					<a-menu-item>
						<a href="javascript:;" v-on:click="updateStatus(row, newStats='Picked Up')">Picked Up</a>
					</a-menu-item>
					</a-menu>
				</a-dropdown>
			</template>

			<template slot="actionsBtn" slot-scope="row">
				<div class="icon-container" style="display: flex; justify-content: space-between;">
					<a href="javascript:;" v-on:click="showModal(row)" style="margin-right: 10px;">
						<v-icon size="25">edit</v-icon>
					</a>
					<a href="javascript:;" v-on:click="DeleteRow(row)">
						<v-icon size="25">delete</v-icon>
					</a>
				</div>
			<MainModal
					v-if="visible"
					:title="modalTitle"
				 	@handleOk="modalHandleOk"
					:handle-cancel="modalHandleCancel"
				>
				<MainForm ref="formFields" :formFields="deliveryInputs" :title="modalTitle"></MainForm>
			</MainModal>
			</template>

		</a-table>
	</a-card>
	<!-- / Delivery Table Card -->

</template>

<script>
import MainModal from '../Modal/MainModal.vue';
import MainForm from '../Forms/MainForm.vue';
import { mapActions } from 'vuex'
	import { mapState } from 'vuex'

	export default ({
		components: {
		  MainModal, MainForm
		},
		props: {
			data: {
				type: Array,
				default: () => [],
			},
			columns: {
				type: Array,
				default: () => [],
			},
		},
		data() {
			return {
				visible: false,
				modalTitle: "Edit Request",
				deliveryInputs: [
      		{ name: 'owner', label: 'Recipient', type:'searchSelect', rules: ['required']},
      		{ name: 'from', label: 'Vendor', placeholder:'', type:'selectBox', 'options': [
						{value: 'Amazon', text: 'Amazon'},
						{value: 'UPS', text: 'UPS'},
						{value: 'Fedex', text: 'Fedex'},
						{value: 'USPS', text: 'USPS'},
						{value: 'Rx', text: 'Rx'},
						{value: 'Dry Cleaning', text: 'Dry Cleaning'},
						{value: 'Groceries', text: 'Groceries'},
						{value: 'Hand Delivery', text: 'Hand Delivery'},
						{value: 'Other', text: 'Other'}],
						rules: ['required']},
      		{ name: 'received_by', label: 'Received By', placeholder:'Enter Name', type:'text', rules: ['']},
      		{ name: 'number_packages', label: 'Number Of Pakcages', placeholder:'', value:'1', type:'text', rules: ['']},
	  		
      	],
				rowDate: '',
				rowStatus: '',
				rowKey: ''
			}
		},
		computed: {
			...mapState({
				membersInfo: state => state.building.membersInfo,
			}),
			formattedDate() {
				const today = new Date();
				const year = today.getFullYear();
				const month = String(today.getMonth() + 1).padStart(2, '0');
				const day = String(today.getDate()).padStart(2, '0');
				return `${month}/${day}/${year}`;
				},
		},
		methods: {
			async DeleteRow(row) {
			if(confirm("Do you really want to delete?")){
				console.log("deleting", row.key);

				try {
					let res = await this.deleteDelivery({delivery: row})
					} catch (e) {
						console.log('modalHandleOk error', e)
					}
			}
			},
			showModal(row) {
				this.deliveryInputs.forEach((inputRow, index) => {
					console.log('inputRow', inputRow)
					if(this.deliveryInputs[index].name === 'created_by_name' || this.deliveryInputs[index].name === 'created_by_apt'){
						this.deliveryInputs[index].value = row['created_by'][this.deliveryInputs[index].name]
					}  else if (inputRow.name === 'owner') {
							const index = this.membersInfo.indexOf(this.membersInfo.find(el => el.member_id === row.member_id));
							const inputIndex = this.deliveryInputs.indexOf(this.deliveryInputs.find(el => el.name === inputRow.name));
							this.deliveryInputs[inputIndex].value = index
							this.deliveryInputs[inputIndex].membersInfo =  this.membersInfo;
					} else {
						try {
							this.deliveryInputs[index].value = row[this.deliveryInputs[index].name]
						} catch (e) {
							console.log('modalHandleOk error', e)
							this.deliveryInputs[index].value = null
						}
					}
				});
				this.visible = true
			  this.rowDate = row.date
				this.rowStatus = row.status
				this.rowKey = row.key
		  	},
			modalHandleCancel() {
				this.visible = false
			},
			async modalHandleOk(handleOnFinish) {
				try {
					let isValid = this.$refs.formFields.validate()
					if(!isValid){
						return;
					}

					let formFields = this.$refs.formFields.formData;
					let member_id = this.membersInfo[formFields.owner].member_id;

					formFields.owner = {
						apartment:this.membersInfo[formFields.owner].apartment,
						name: this.membersInfo[formFields.owner].name,
						email: this.membersInfo[formFields.owner].email,
					}
					let res = await this.updateDelivery({delivery: {...formFields,
						 ...{date: this.rowDate, status: this.rowStatus, key: this.rowKey, member_id}}})
					if(res) {
						this.$refs.formFields.onFinish(true);
						this.visible = false;
					} else {
						this.$refs.formFields.onFinish(false);
					}
				} catch (e) {
					this.$refs.formFields.onFinish(false);
				} finally {
					handleOnFinish()
				}
		  	},
			async updateStatus(row, newStats) {
				try {
					let pickup_time = '';
					if (newStats === 'Picked Up') {
						pickup_time = this.formattedDate;
					} else {
						pickup_time = '';
					}
					let res = await this.updateDelivery({delivery: {status: newStats, key: row.key, pickup_time: pickup_time}})
				} catch (e) {
					console.log('updateStatus error', e)
				}
			},
		  ...mapActions('deliveries', ['updateDelivery', 'deleteDelivery'])
		},
	})

</script>
<style scoped>
.icon-container {
  display: flex;
  justify-content: space-between;
}


</style>